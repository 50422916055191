import React from 'react';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';
// import OpenIDClient from '../libs/client';
// import { logOut } from '../services/vidcredentials';
import CaminsLogo2 from '../../assets/images/camins-logo-2.svg';
import LogoutIcon from '../../assets/icons/logout.svg';
import { logOut } from '../../services/vidcredentials';

function Header() {
  const navigate = useNavigate();
  // const signInWithVIDchain = async () => {
  //   const client = OpenIDClient.getInstance().getProvider();
  //   client.wipeTokens();
  //   await client.callback();
  //   await client.getToken({
  //     scopes: {
  //       request: ['openid'],
  //     },
  //   });
  // };
  //
  const doLogOut = async () => {
    logOut();
    navigate('/');
  };

  return (
    <div className="header-container">
      <Link to="/">
        <img
          alt="Camins logo"
          src={CaminsLogo2}
          className="header__camins-logo"
        />
      </Link>
      <button
        type="button"
        className="header__logout-button"
        onClick={doLogOut}
      >
        <img alt="Logout" src={LogoutIcon} />
        <h1>Sortir</h1>
      </button>
    </div>
  );
}

export default Header;
