import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function ProtectedRoutes() {
  const presentation = localStorage.getItem('access_token');
  if (!presentation) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
}

export default ProtectedRoutes;
