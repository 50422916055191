import React from 'react';
import './IssuanceStart.css';
import { useNavigate } from 'react-router-dom';
import { credentialTypes } from '../../constants/credentials';
import CustomButton from '../CustomButton/CustomButton';

function IssuanceStart() {
  const navigate = useNavigate();
  return (
    <section className="welcome-section">
      <h1 className="welcome-section__header">
        Benvinguts al portal de credencials
      </h1>
      <section className="welcome-section__credentials-section">
        {credentialTypes.map((item, index) => (
          <div
            className="credentials-section__credential-container"
            key={`credential-container-${item.title}`}
          >
            <h2 className="credentials-section__credential-container_header">
              {item.description}
            </h2>
            <CustomButton
              handleClick={async () => {
                if (item.to) {
                  navigate(item.to);
                }
              }}
              text={`${index + 1}. ${item.title}`}
              showArrow
              big
            />
          </div>
        ))}
      </section>
    </section>
  );
}

export default IssuanceStart;
