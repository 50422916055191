import React, { FC } from 'react';
import Header from '../components/Header/Header';

function withHeader(Element: () => JSX.Element | null): FC {
  return function (props) {
    return (
      <main>
        <Header />
        <Element {...props} />
      </main>
    );
  };
}

export default withHeader;
