import {
  Action, CredentialTypes, CredentialUrls, FormDetail,
} from '../dtos/credentials';

export const actionsPerUrl: { [key in CredentialUrls]: Action[] } = {
  'educational-id': [{
    text: 'Vols la teva credencial verificable? Sol·licita l\'emisió de',
    textQr: 'Escaneja el codi QR amb la teva cartera digital per a obtenir la credencial',
    boldText: 'EducationalID',
    buttonText: 'Obtenir EducationalID',
    callback: '',
  }],
  'issue-credential': [{
    text: 'Aconseguir credencial',
    boldText: '“Estudiant titulat”',
    buttonText: 'Emetre Credencial',
    callback: '',
    credentialType: CredentialTypes.ESTUDIANT_TITULAT,
  },
  {
    text: 'Aconseguir credencial',
    boldText: '“Certificat acadèmic oficial (CAO)”',
    buttonText: 'Emetre Credencial',
    callback: '',
    credentialType: CredentialTypes.CAO,
  },
  {
    text: 'Aconseguir credencial',
    boldText: '“Acreditació d’idioma”',
    buttonText: 'Emetre Credencial',
    callback: '',
    credentialType: CredentialTypes.NIVELL_IDIOMES,
  }],
  validate: [{
    text: 'Comprova la validesa de les teves credencials',
    textQr: 'Escaneja el codi QR amb la teva cartera digital per a validar la credencial',
    buttonText: 'Valida la meva credencial',
    callback: '',
  }],
  authenticate: [{
    text: 'Autentica\'t amb la teva credencial EducationalID per a poder accedir al portal d\'emisió de credencials',
    textQr: 'Escaneja el codi QR amb la teva cartera digital per a autenticarte',
    buttonText: 'Presentar credencial',
    callback: '',
  }],
};
export const credentialTypes = [
  {
    title: 'Obtenir EducationalID',
    description: 'Vols la teva credencial verificable? Sol·licita l’emisió d\'EducationalID',
    destination: '',
    to: `/credential/${CredentialUrls.EDUCATIONAL_ID}`,
  },
  {
    title: 'Autentica\'t',
    description: 'Autentica\'t al portal presentant la teva acreditació EducationalID',
    destination: '',
    to: `/credential/${CredentialUrls.AUTHENTICATE}`,
  },
  {
    title: 'Validar Credencial',
    description: 'Comprova la validesa de les teves credencials',
    destination: '',
    to: `/credential/${CredentialUrls.VALIDATE}`,
  },
];

// eslint-disable-next-line max-len
export const credentialDetailsFormat: { [key: string]: FormDetail[] } = {
  EducationalId: [
    {
      name: 'Nom:',
      value: '$.credentialSubject.firstName',
    },
    {
      name: 'Cognom:',
      value: '$.credentialSubject.familyName',
    },
    {
      name: 'Universitat:',
      value: '$.credentialSubject.schacHomeOrganization',
    },
    {
      name: 'Edu Personal Principal Name:',
      value: '$.credentialSubject.eduPersonPrincipalName',
    },
    {
      name: 'Edu Person Primary Affiliation:',
      value: '$.credentialSubject.eduPersonPrimaryAffiliation',
    },
  ],
  EstudiantTitulat: [
    {
      name: 'Carrera:',
      value: '$.credentialSubject.achieved[0].title',
    },
    {
      name: 'Universitat:',
      value: '$.credentialSubject.achieved[0].wasAwardedBy.awardingBody[0]',
    },
    {
      name: 'Calificació:',
      value: '$.credentialSubject.achieved[0].specifiedBy[0].eqflLevel',
    },
    {
      name: 'Data:',
      value: '$.credentialSubject.achieved[0].wasAwardedBy.awardingDate',
    },
    {
      name: 'Estat:',
      value: '$.credentialSubject.achieved[0].identifier[0].schemeID',
    },
  ],
  NivellIdiomes: [
    {
      name: 'Idioma:',
      value: '$.credentialSubject.achieved[0].title',
    },
    {
      name: 'Universitat:',
      value: '$.credentialSubject.achieved[0].identifier[0].value',
    },
    {
      name: 'Títol:',
      value: '$.credentialSubject.achieved[0].wasInfluencedBy[0].title',
    },
    {
      name: 'Començament:',
      value: '$.credentialSubject.achieved[0].wasInfluencedBy[0].startedAtTime',
    },
    {
      name: 'Final:',
      value: '$.credentialSubject.achieved[0].wasInfluencedBy[0].endedAtTime',
    },
  ],
  EuropeanDigitalCredential: [
    {
      name: 'Títol:',
      value: '$.credentialSubject.hasClaim[1].title.ca',
    },
    {
      name: 'Tipus:',
      value: '$.credentialSubject.hasClaim[1].specifiedBy.entitledBy.title.ca',
    },
    {
      name: 'Data:',
      value: '$.credentialSubject.hasClaim[1].specifiedBy.dateModified',
    },
    {
      name: 'Universitat:',
      value: '$.credentialSubject.hasClaim[0].awardedBy.awardingBody.legalName.ca',
    },
    {
      name: 'Àmbit:',
      value: '$.credentialSubject.hasClaim[1].specifiedBy.entitledBy.category.ca',
    },
  ],
};

// eslint-disable-next-line max-len
export const IS_PHONE_REGEX = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
