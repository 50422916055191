import React from 'react';
import './ValidationOutcome.css';
import { useNavigate, useParams } from 'react-router-dom';
import jsonpath from 'jsonpath';
import { credentialDetailsFormat } from '../../constants/credentials';
import CustomButton from '../CustomButton/CustomButton';
import { CredentialUrls } from '../../dtos/credentials';
import CredentialBadgeImage from '../../assets/images/credential_badge.svg';
import PhoneFailureImage from '../../assets/images/phone_failure.svg';

interface ValidationOutcomeProps {
  credential?: any;
  retryLocation?: string;
}

function ValidationOutcome({
  credential,
  retryLocation,
}: ValidationOutcomeProps) {
  const navigate = useNavigate();
  const { status } = useParams();

  const success = !(!status || !credential || status !== 'success');

  const message = 'La credencial no ha pogut ser validada degut a un error durant el procés';
  const navigateTo = success ? '/' : `/credential/${retryLocation}`;
  let credentialType;
  const credentialTypes = Object.keys(credentialDetailsFormat);
  if (success) {
    // eslint-disable-next-line prefer-destructuring
    credentialType = credential.type.find(
      (element: string) => credentialTypes.includes(element),
    );
  }

  return (
    <div>
      {success && (
      <div className="validated-container">
        <section className="validated-container__section">
          <h2 className="validated-container__section_header">
            La teva credencial ha
            pogut ser validada satisfactòriament
          </h2>
          <div className="credential-container">
            <section className="credential-container__image-wrapper">
              <img
                alt="Credential badge"
                src={CredentialBadgeImage}
                className="validated-container__section_image"
              />
            </section>
            {!!credentialType && (
            <section className="credential-container__contents">
              <section className="credential-container__contents_type">
                <h3>Tipus de credencial:</h3>
                <h2>
                  {credentialType}
                </h2>
              </section>
              <section className="credential-container__contents_name">
                <h3>{credentialDetailsFormat[credentialType][0].name}</h3>
                {/* eslint-disable-next-line max-len */}
                <h2>
                  {jsonpath.query(
                    credential,
                    credentialDetailsFormat[credentialType][0].value,
                  )}
                </h2>
              </section>
              <section className="credential-container__contents_surname">
                <h3>{credentialDetailsFormat[credentialType][1].name}</h3>
                {/* eslint-disable-next-line max-len */}
                <h2>
                  {jsonpath.query(
                    credential,
                    credentialDetailsFormat[credentialType][1].value,
                  )}
                </h2>
              </section>
              <section className="credential-container__contents_university">
                <h3>{credentialDetailsFormat[credentialType][2].name}</h3>
                {/* eslint-disable-next-line max-len */}
                <h2>
                  {jsonpath.query(
                    credential,
                    credentialDetailsFormat[credentialType][2].value,
                  )}
                </h2>
              </section>
              <section className="credential-container__contents_year">
                <h3>{credentialDetailsFormat[credentialType][3].name}</h3>
                {/* eslint-disable-next-line max-len */}
                <h2>
                  {jsonpath.query(
                    credential,
                    credentialDetailsFormat[credentialType][3].value,
                  )}
                </h2>
              </section>
              <section className="credential-container__contents_expiration">
                <h3>{credentialDetailsFormat[credentialType][4].name}</h3>
                {/* eslint-disable-next-line max-len */}
                <h2>
                  {jsonpath.query(
                    credential,
                    credentialDetailsFormat[credentialType][4].value,
                  )}
                </h2>
              </section>
            </section>
            )}
            {!credentialType && (
              <section className="credential-container__default-content-wrapper">
                <section className="credential-container__contents_type">
                  <h3>Tipus de credencial:</h3>
                  <h2>
                    {credential.type[credential.type.length - 1]}
                  </h2>
                </section>
              </section>
            )}
          </div>
          <CustomButton
            handleClick={
                () => navigate(`/credential/${CredentialUrls.VALIDATE}`)
              }
            text="Valida una altra credencial"
            showArrow={false}
          />
        </section>
      </div>
      )}
      {!success && (
      <div className="page-container">
        <section className="page-container__section">
          <h2 className="page-container__section_header">{message}</h2>
          <img
            alt="Failure"
            src={PhoneFailureImage}
            className="page-container__section_image"
          />
          <CustomButton
            handleClick={() => navigate(navigateTo)}
            text="Torna-ho a intentar"
            showArrow={false}
          />
        </section>
      </div>
      )}
    </div>

  );
}

ValidationOutcome.defaultProps = {
  credential: undefined,
  retryLocation: undefined,
};

export default ValidationOutcome;
