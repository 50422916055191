import React from 'react';
import './HomePage.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import IssuanceStart from '../../components/IssuanceStart/IssuanceStart';
import PlaceholderBackgroundImage
  from '../../assets/images/low-q-landing-background.jpeg';
import BackgroundImage from '../../assets/images/landing-background.svg';
import CaminsLogo from '../../assets/images/camins-logo.svg';
import EntitiesImage from '../../assets/images/entitites.svg';

function HomePage() {
  return (
    <main className="main-container">
      <LazyLoadImage
        alt="Background"
        src={BackgroundImage}
        placeholderSrc={PlaceholderBackgroundImage}
        className="main-container_background-image"
      />
      <img
        alt="Camins logo"
        src={CaminsLogo}
        className="main-container_camins-logo"
      />
      <img
        alt="Camins logo"
        src={EntitiesImage}
        className="main-container_entities-logo"
      />
      <IssuanceStart />
    </main>
  );
}

export default HomePage;
