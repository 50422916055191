import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import IssuanceOutcome from '../../components/IssuanceOutcome/IssuanceOutcome';
import withHeader from '../../hoc/withHeader';

function ProcessOutcome() {
  const { status } = useParams();
  const { state } = useLocation();

  if (state && state.credential && status === 'success') {
    return (
      <IssuanceOutcome
        buttonText="Acceptar"
        credential={state.credential}
      />
    );
  }

  if (status === 'failure') {
    return <IssuanceOutcome buttonText="Torna-ho a intentar" retryLocation={state.retryLocation} />;
  }

  return null;
}

export default withHeader(ProcessOutcome);
