import React from 'react';
import './QrCode.css';

interface QrCodeProps {
  base64img: string,
}

function QrCode({
  base64img,
}: QrCodeProps) {
  return (
    <div className="qrCode-container">
      <img src={base64img} alt="qr-code" />
    </div>
  );
}

export default QrCode;
