import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import withHeader from '../../hoc/withHeader';
import ValidationOutcome from '../../components/ValidationOutcome/ValidationOutcome';

function ValidationOutcomeScreen() {
  const { status } = useParams();
  const { state } = useLocation();

  if (state && state.pollingDataResponse && status === 'success') {
    return (
      <ValidationOutcome credential={state.pollingDataResponse?.credential} />
    );
  }

  if (status === 'failure') {
    return <ValidationOutcome retryLocation={state.retryLocation} />;
  }

  return null;
}

export default withHeader(ValidationOutcomeScreen);
