export type Action = {
  text: string;
  textQr?: string;
  boldText?: string;
  buttonText: string;
  callback: string;
  credentialType?: string;
};

export type FormDetail = {
  name: string;
  value: string;
};

export enum CredentialUrls {
  EDUCATIONAL_ID = 'educational-id',
  ISSUE_CREDENTIAL = 'issue-credential',
  VALIDATE = 'validate',
  AUTHENTICATE = 'authenticate',
}

export enum CredentialTypes {
  NIVELL_IDIOMES = 'NivellIdiomes',
  ESTUDIANT_TITULAT = 'EstudiantTitulat',
  EDUCATIONAL_ID = 'EducationalId',
  CAO = 'CAO',
}
