import React from 'react';
import './IssuanceOutcome.css';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../CustomButton/CustomButton';
import PhoneSuccessImage from '../../assets/images/phone_success.svg';
import PhoneFailureImage from '../../assets/images/phone_failure.svg';

interface IssuanceOutcomeProps {
  buttonText: string;
  credential?: string;
  retryLocation?: string;
}

function IssuanceOutcome({
  credential,
  buttonText,
  retryLocation,
}: IssuanceOutcomeProps) {
  const navigate = useNavigate();
  const success = !!credential;
  const message = success
    ? `La teva credencial ${credential} ha pogut ser 
       emesa correctament al teu telèfon.`
    : 'No docs’ha pogut emetre la credencial degut a un error durant el procés';
  const navigateTo = success ? '/credential/issue-credential' : `/credential/${retryLocation}`;

  return (
    <div className="page-container">
      <section className="page-container__section">
        <h2 className="page-container__section_header">{message}</h2>
        <img
          alt={credential ? 'Success' : 'Failure'}
          src={credential ? PhoneSuccessImage : PhoneFailureImage}
          className="page-container__section_image"
        />
        <CustomButton
          handleClick={() => navigate(navigateTo)}
          text={buttonText}
          showArrow={false}
        />
      </section>
    </div>
  );
}

IssuanceOutcome.defaultProps = {
  credential: undefined,
  retryLocation: undefined,
};
export default IssuanceOutcome;
