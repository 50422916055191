import jwtDecode from 'jwt-decode';

export const validateToken = () => {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    const decodedToken: any = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    const isExpired = currentTime > decodedToken.exp;
    if (isExpired) {
      localStorage.setItem('access_token', '');
      localStorage.setItem('did', '');
      localStorage.setItem('code', '');
    }
  }
};

export const processToken = (token: string) => {
  const decodedToken: any = jwtDecode(token);
  localStorage.setItem('access_token', token);
  localStorage.setItem('did', decodedToken.userInfo.did);
};
