import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './screens/HomePageScreen/HomePage';
import IssueCredential from './screens/IssueCredential';
import IssuanceProcess from './screens/IssuanceProcessScreen/IssuanceProcess';
import ProcessOutcome from './screens/ProcessOutcomeScreen/ProcessOutcome';
import ProtectedRoutes from './providers/ProtectedRoutes';
import ValidationOutcomeScreen from './screens/ValidationOutcomeScreen/ValidationOutcomeScreen';

function App() {
  // TODO: use ID for requests
  localStorage.setItem('ID-EducationalID', '44444444A');
  localStorage.setItem('ID-EstudiantTitulat', '47822239H');
  localStorage.setItem('ID-NivellIdiomes', '37734744R');
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/credential/:type" element={<IssueCredential />} />
        <Route
          path="/issuance-process/:status"
          element={<ProcessOutcome />}
        />
        <Route
          path="/validation-process/:status"
          element={<ValidationOutcomeScreen />}
        />
        <Route element={<ProtectedRoutes />}>
          <Route path="/issuance-process" element={<IssuanceProcess />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
