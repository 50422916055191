import React from 'react';
import './CustomButton.css';
import ArrowIcon from '../../assets/icons/arrow.svg';

interface ButtonProps {
  handleClick: () => void;
  text: string;
  showArrow: boolean;
  big?: true;
}

function CustomButton({
  handleClick,
  text,
  showArrow,
  big,
}: ButtonProps) {
  return (
    <button
      type="button"
      className="custom-button"
      onClick={handleClick}
      style={{
        justifyContent: showArrow ? 'space-between' : 'center',
        width: big ? '90%' : 'fit-content',
      }}
    >
      <h2>{text}</h2>
      {' '}
      {showArrow ? (
        <img
          alt="Arrow"
          src={ArrowIcon}
        />
      ) : null}
    </button>
  );
}

CustomButton.defaultProps = {
  big: undefined,
};

export default CustomButton;
