const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_URL;
const BACKEND_ENDPOINTS = {
  AUTHORIZE: `${BACKEND_BASE_URL}/authorize`,
  AUTHORIZE_PRESENTATION: `${BACKEND_BASE_URL}/authorize-presentation`,
  VALIDATE: `${BACKEND_BASE_URL}/validate`,
  REVOKED: `${BACKEND_BASE_URL}/revoked`,
  ISSUANCE_ESTUDIANT_TITULAT: `${BACKEND_BASE_URL}/estudiant-titulat`,
  ISSUANCE_NIVELL_IDIOMES: `${BACKEND_BASE_URL}/nivell-idiomes`,
  ISSUANCE_CAO: `${BACKEND_BASE_URL}/cao`,
};
const BACKEND = {
  BASE_URL: BACKEND_BASE_URL,
  ENDPOINTS: BACKEND_ENDPOINTS,
};

export default BACKEND;
