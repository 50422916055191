import React from 'react';
import { useParams } from 'react-router-dom';
import CredentialLanding from '../components/CredentialLanding/CredentialLanding';
import { CredentialUrls } from '../dtos/credentials';
import { actionsPerUrl } from '../constants/credentials';
import withHeader from '../hoc/withHeader';

function IssueCredential() {
  const { type } = useParams();

  if (!type) return null;

  if (type in actionsPerUrl) {
    return (
      <CredentialLanding
        isValidator={type === 'validate' || type === 'authenticate'}
        actions={actionsPerUrl[type as CredentialUrls]}
      />
    );
  }

  return null;
}

export default withHeader(IssueCredential);
